import React from 'react';

import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { SelectProps } from 'antd/lib/select';

import { useItemsProviderContext, HandleChangeEventElement } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import Autocomplete, { OptionType, AutocompleteProps } from '@common/react/components/Forms/Autocomplete/Autocomplete';
import MultiSelectAuto, { MultiSelectAutoProps } from '@common/react/components/UI/MultiSelectAuto/MultiSelectAuto';

import { Doctor } from '@app/objects/Doctor';

import '@app/scss/components/doctorAutocompleteFilter.scss';

interface ComponentProps {
	currentValue?: number | undefined;
	type?: string;
	renderTitle?: (item: Doctor) => any | string;
	renderOption?: (item: Doctor) => OptionType;
	handleChangeAndRefresh?: (target: HandleChangeEventElement, option?: any) => void;
	param?: string;
	multiple?: boolean;
	placeholder?: string | undefined;
	onSelect?: (value, option) => void;
	onChange?: (value) => void;
	value?: string | undefined;
	items?: Array<any> | undefined;
	loadOnFocus?: boolean;
	antdProps?: AutoCompleteProps & Partial<SelectProps>;
	params?: any;
	isClear?: boolean;
	disabled?: boolean;
	paramName?: string;
	isDisabled?: boolean;
	withoutDisabled?: boolean;
	autocompleteProps?: Partial<AutocompleteProps>;
	multiSelectAutoProps?: Partial<MultiSelectAutoProps>;
	withAdditionalOption?: boolean;
	additionalOptionLabel?: string;
	emptyText?: string;
	className?: string;
}

const DoctorAutocompleteFilter: React.FC<ComponentProps> = ({
	type,
	renderTitle,
	renderOption,
	multiple,
	placeholder,
	onSelect,
	onChange,
	handleChangeAndRefresh,
	param,
	value,
	items,
	loadOnFocus,
	antdProps,
	params,
	isClear,
	disabled,
	paramName,
	withoutDisabled,
	isDisabled: isDisabledProps,
	autocompleteProps,
	multiSelectAutoProps,
	withAdditionalOption,
	additionalOptionLabel,
	emptyText,
	className = 'pull-right',
	...props
}) => {
	const [isDisabled, setDisabled] = React.useState<boolean>(isDisabledProps || false);
	const usedParams = withoutDisabled ? params : { ...params, disabled: isDisabled };
	const context = useItemsProviderContext();
	const currentValue = props.currentValue || context?.state?.filters?.[param || 'doctorId'];
	const withoutDoctorId = -2;
	const withoutDoctorLabel = additionalOptionLabel || 'Without Doctor';

	return (
		<div className={`doctor-filter-wrapper ${className}`}>
			{!withoutDisabled && (
				<div
					className="doctor-filter-switch"
				>
					<button
						type="button"
						title={isDisabled ? 'search Inactive Providers' : 'search active Providers'}
						className={`btn btn-default ${isDisabled ? '' : 'doctor-filter__checked'}`}
						onClick={() => setDisabled((v) => !v)}
					>
						<i className="fa fa-user-md" />
					</button>
				</div>
			)}
			{multiple && !handleChangeAndRefresh && !param
				? (
					<MultiSelectAuto
						{...multiSelectAutoProps}
						type="doctorAutocompleteList"
						renderTitle={renderTitle ?? 'nameEn'}
						placeholder={placeholder ?? 'Providers...'}
						loadOnFocus={loadOnFocus ?? true}
						renderOption={renderOption}
						disabled={disabled}
						onChange={(value: any) => {
							onChange && onChange(value);
						}}
						items={items}
						antdProps={{
							...(antdProps ?? {
								defaultValue: [],
								allowClear: true,
							}),
							suffixIcon: null,
						}}
						params={usedParams}
						isClear={isClear ?? false}
						paramName={paramName}
					/>
				)
				: (
					<Autocomplete<Doctor>
						{...autocompleteProps}
						type="doctorAutocompleteList"
						renderTitle={renderTitle ?? 'nameEn'}
						renderOption={renderOption}
						emptyText={emptyText}
						onSelect={(value, option) => {
							if (onSelect) {
								onSelect(value, option);
							} else if (handleChangeAndRefresh && param) {
								handleChangeAndRefresh({
									currentTarget: {
										name: param,
										value: `${value}`,
									},
								}, option);
							}
						}}
						onChange={(value = '') => {
							if (onChange) {
								onChange(value);
							} else if (handleChangeAndRefresh && param && value === '' && currentValue !== -1 && currentValue !== undefined) {
								handleChangeAndRefresh({
									currentTarget: {
										name: param,
										value: -1,
									},
								});
							}
						}}
						value={value}
						disabled={disabled}
						loadOnFocus={loadOnFocus ?? true}
						antdProps={antdProps ?? {
							placeholder: placeholder ?? 'Provider...',
						}}
						params={usedParams}
						additionalOptions={withAdditionalOption
							? [{
								label: withoutDoctorLabel, value: withoutDoctorLabel, key: `${withoutDoctorId}`, item: { id: `${withoutDoctorId}` },
							}] : []}
						isClear={isClear ?? false}
						paramName={paramName}
					/>
				)}
		</div>
	);
};

export default DoctorAutocompleteFilter;
