import React from 'react';

import Button from 'antd/lib/button';
import { Field } from 'formik';
import Tooltip from 'antd/lib/tooltip';
import Switch from 'antd/lib/switch';
import Select from 'antd/lib/select';

import Wysiwyg from '@common/react/components/Forms/Wysiwyg/WysiwygWithCodeMirror';
import File from '@common/react/components/Forms/Files/File';
import Colorbox from '@common/react/components/UI/Colorbox/Colorbox';
import { removeFromArrayByIndex } from '@common/typescript/utils/immutability';
import { File as FileInterface, FileType } from '@common/typescript/objects/FileInterface';
import FormikField from '@common/react/components/Forms/FormikField/FormikField';

import { Feature } from '@commonTuna/react/objects/CompanyFeature';

import { useCompanyFeatureProviderContext } from '@app/components/UI/CompanyFeaturesSetter/CompanyFeaturesSetter';
import { extensions } from '@app/components/UI/SendEmailModal/SendEmailReadonly';
import { useCompanySettingsContext } from '@app/components/UI/CompanySettingsSetter/CompanySettingsSetter';

const SendEmailEdit: React.FC<{ loading, error }> = ({ loading, error }) => {
	const [withCopies, setWithCopies] = React.useState(false);
	const { companySettings } = useCompanySettingsContext();
	const { checkFeatureAccess } = useCompanyFeatureProviderContext();
	const hasCustomMailBox = checkFeatureAccess(Feature.CustomMailBox) && !!companySettings.sender;
	const [img, setImg] = React.useState<FileInterface | null>(null);
	const emailFeatureIsEnabled = checkFeatureAccess(Feature.Email);

	return <>
		<div className="clearfix">
			<div
				style={{
					margin: '-6px 0 15px', lineHeight: '34px', display: 'flex', flexDirection: 'row', gap: 20,
				}}
			>
				<div
					style={{
						fontSize: 14, lineHeight: 1.5, flex: '1 1 auto', width: '1px',
					}}
				>
					<strong>Sender:</strong>
					<br />
					<div className="ellipsis">
						<Field name="">
							{({ form }) => {
								const sender = form.values.useCustomMailboxConfig ? companySettings.sender : 'support@northerntuna.com';
								return <Tooltip title={sender} color="white" placement="topLeft">
									<span>
										{sender}
									</span>
								</Tooltip>;
							}}
						</Field>
					</div>
				</div>
				<div className="appointment-popup email-popup-menu__edit">
					<Field name="useCustomMailboxConfig">
						{({ form }) => hasCustomMailBox && (
							<Switch
								title="Use Custom Mailbox"
								size="small"
								checkedChildren="Custom Mailbox"
								unCheckedChildren="Common Mailbox"
								checked={form.values.useCustomMailboxConfig}
								onChange={(checked) => form.setValues(
									(values) => ({ ...values, useCustomMailboxConfig: checked }),
								)}
							/>
						)}
					</Field>
				</div>
			</div>
		</div>
		<div
			className="send-email-modal__form custom-scroll send-email-modal__edit"
		>
			<FormikField
				containerClassName="form-group"
				title="To"
				fieldName="to"
				render={({ form }) => <Select
					mode="tags"
					value={form.values.to}
					style={{ width: '100%' }}
					onChange={(value) => {
						form.setValues({ ...form.values, to: value });
					}}
				/>}
			/>
			<div className="clearfix" style={{ marginTop: -7 }}>
				<Switch
					className="pull-right"
					size="small"
					checkedChildren="copies"
					unCheckedChildren="copies"
					checked={withCopies}
					onChange={setWithCopies}
				/>
			</div>
			<div style={{ display: withCopies ? undefined : 'none' }}>
				<FormikField
					containerClassName="form-group"
					title="Copy"
					fieldName="cc"
					render={({ form }) => {
						return <Select
							mode="tags"
							style={{ width: '100%' }}
							value={form.values.cc}
							onChange={(value) => {
								form.setValues({ ...form.values, cc: value });
							}}
						/>;
					}}
				/>
				<FormikField
					containerClassName="form-group"
					title="Blind Copy"
					fieldName="bcc"
					render={({ form }) => {
						return <Select
							mode="tags"
							style={{ width: '100%' }}
							value={form.values.bcc}
							onChange={(value) => {
								form.setValues({ ...form.values, bcc: value });
							}}
						/>;
					}}
				/>
			</div>
			<FormikField
				containerClassName="form-group"
				title="Subject"
				fieldName="subject"
			/>
			<Field name="files">
				{({ form }) => <div className="clearfix" style={{ height: 0 }}>
					<File
						buttonCaption={<i className="fa fa-paperclip" style={{ margin: 0 }} />}
						asButton
						buttonClassName="btn btn-sm btn-primary pull-right btn-attachments"
						objectId={form.values.patientId}
						type="patient"
						update={(file) => form.setValues({
							...form.values,
							attachments: [
								...form.values.attachments, {
									id: -1, objectId: form.values?.patientId ?? -1, description: '', file,
								},
							],
						})}
						fileType={FileType.File}
					/>
				</div>}
			</Field>
			<FormikField
				containerClassName="form-group"
				title="Content"
				fieldName="html"
				render={({ form }) => <Wysiwyg
					options={{ disabled: false }}
					onChange={(value: string) => {
						form.setValues({ ...form.values, html: value });
					}}
					customPlugins={['fontsize', 'fontfamily', 'lineheight']}
				/>}
			/>
			<Field name="files">
				{({ form }) => {
					const imageIndex = form.values.attachments.filter((f) => extensions.includes(f.file?.extension?.toLowerCase()))
						.findIndex((image) => image.file?.id === img?.file?.id);
					return <>
						<Colorbox
							files={form.values.attachments || []}
							defaultIdx={imageIndex > 0 ? imageIndex : null}
							onCancel={() => setImg(null)}
							zIndex={1002}
						/>
						{!!form.values.attachments.length && (
							<div className="form-group mt10 mb10">
								{form.values.attachments.map((a, i) => {
									const isImage = extensions.includes(a.file?.extension?.toLowerCase());
									/* eslint-disable */
									return (
										<a
											href={isImage ? undefined : a.file?.src}
											className="attached-file mr10 mb10 clearfix"
											key={a.id}
											download={!isImage}
											{...(isImage ? {} : { target: '_blank', rel: 'noreferrer' })}
											onClick={(e) => {
												if (!isImage) return;
												e.preventDefault();
												e.stopPropagation();
												setImg(a);
											}}
										>
											{isImage ? (
												<div
													className="attached-file__image"
												>
													<img
														className="chat-file-tag-image"
														src={a.file?.src}
														alt={a.file?.name}
													/>
												</div>
											) : <i className="fa fa-file" />}
											&nbsp;
											{a.file?.name}
											<i
												className="fa fa-times attached-file__remove"
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													form.setValues({
														...form.values,
														attachments: removeFromArrayByIndex(form.values.attachments, i),
													});
												}}
											/>

										</a>
									);
									/* eslint-enable */
								})}
							</div>
						)}
					</>;
				}}
			</Field>
		</div>
		<div className="clearfix text-center">
			<Button
				loading={loading}
				key="send"
				htmlType="submit"
				className={`btn btn-primary ${error ? 'mb10' : ''}`}
				style={{ color: 'white' }}
				disabled={!emailFeatureIsEnabled}
				title={!emailFeatureIsEnabled ? 'Email feature is disabled' : ''}
			>
				Send
			</Button>
		</div>
	</>;
};

export default SendEmailEdit;
