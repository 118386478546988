import * as React from 'react';

import Popover from 'antd/lib/popover';

import SwitcherProvider from '@common/react/components/Core/SwitcherProvider/SwitcherProvider';
import { Nullable } from '@common/typescript/objects/Nullable';

import CustomEventStatusList from '@app/components/Pages/Admin/Calendar/CustomEventStatusList';
import { Appointment, AppointmentStatus } from '@app/objects/Appointment';
import { getContrast } from '@app/components/Pages/Admin/Calendar/calendarUtils';
import '@app/scss/components/statusSwitcher.scss';

const storeName = 'statusItems';
const typeName = 'statuses';

interface StatusSwitcherProps {
	appointment: Appointment;
	onSelect?: (status: AppointmentStatus | undefined) => void;
	popupContainerSelector?: string;
	onlyView?: boolean;
}

interface StatusProviderProps {
	appointment: Appointment;
	children?: React.ReactNode | ((item: Nullable<AppointmentStatus>) => React.ReactNode) | undefined;
}

export const StatusNotificationHandler: React.FC = () => {
	return <SwitcherProvider<AppointmentStatus>
		storeName={storeName}
		type={typeName}
		getObjectFromNotification={(data) => data.status}
		notificationObjectType="Appointment"
	/>;
};

export const StatusProvider: React.FC<StatusProviderProps> = ({
	appointment,
	children,
}) => {
	return <SwitcherProvider<AppointmentStatus>
		storeName={storeName}
		type={typeName}
		id={appointment.id}
	>
		{({ state: { item } }) => (children && typeof children === 'function' ? children(item || appointment.status) : children)}
	</SwitcherProvider>;
};

const StatusSwitcher: React.FC<StatusSwitcherProps> = ({
	appointment,
	onSelect,
	popupContainerSelector,
	onlyView = false,
}) => {
	const [visible, setVisible] = React.useState<boolean>(false);

	const getPopupContainer = (triggerNode: HTMLElement) => (popupContainerSelector
		&& document.querySelector<HTMLDivElement>(popupContainerSelector)) || document.body;

	return <SwitcherProvider
		storeName={storeName}
		type={typeName}
		id={appointment.id}
	>
		{({ state: { item: status }, actions: { change } }) => {
			const statusColor = appointment.status?.color;
			const statusStyle = statusColor ? {
				backgroundColor: statusColor,
				color: getContrast(statusColor),
			} : {};

			const data = React.useMemo(() => {
				const statusColor = status?.color;
				const style = statusColor ? {
					backgroundColor: statusColor,
					color: getContrast(statusColor),
				} : {};
				return { style, status };
			}, [status]);

			return <Popover
				content={(
					<CustomEventStatusList
						appointment={{
							...appointment,
							statusId: data.status?.id || appointment.statusId,
							status: data.status || appointment.status,
						}}
						onSelect={(status) => {
							setVisible(false);
							onSelect && onSelect(status);
							change(appointment.id, status);
						}}
					/>
				)}
				title="Change status"
				open={!onlyView && visible}
				trigger="click"
				destroyTooltipOnHide
				placement="rightTop"
				onOpenChange={setVisible}
				overlayClassName="custom-event__status-tooltip ignore-select-slot"
				getPopupContainer={getPopupContainer}
			>
				<span className="custom-event-status ignore-select-slot" style={data.status ? data.style : statusStyle} title="Change Status">
					{data.status?.nameEn || appointment.status?.nameEn}
				</span>
			</Popover>;
		}}
	</SwitcherProvider>;
};

export default StatusSwitcher;
