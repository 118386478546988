import * as React from 'react';
import { Route, Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { loadable, loadableDelay } from '@common/react/loadable/loadableSettings';
import { useSizableProviderContext } from '@common/react/components/Core/SizableWithServerPage/SizableWithServerPage';

import { UserGroupType } from '@commonTuna/react/objects/UserGroup';

import { params } from '@app/components/UI/TunaLoader';
import SecureRouteWrapper from '@app/components/Routes/SecureRoute/SecureRouteWrapper';
import { useUserGroupContext } from '@app/components/UI/UserGroupProvider/UserGroupProvider';
import { StatusNotificationHandler } from '@app/components/Pages/Admin/Calendar/StatusSwitcher';

const DashboardLayout = loadable(() => loadableDelay(
	/* webpackChunkName: "DashboardLayout" */ import('@app/components/Layouts/DashboardLayout/DashboardLayout'),
), params);

interface Props {
	component: any;
	redirectPath?: string;
	path: string;
	title?: string;
	dashboard?: boolean;
}

const DashboardRoute: React.FC<Props> = ({
	component: Component, redirectPath = '/', dashboard = true, title, ...rest
}) => {
	const { checkUserGroupAccess } = useUserGroupContext();
	const context = useSizableProviderContext();

	return (
		<SecureRouteWrapper path={rest.path as string} redirectPath={redirectPath}>
			<StatusNotificationHandler />
			{(checkUserGroupAccess(UserGroupType.DashboardMenuInWizard) || dashboard) && !context.state
				? (
					<DashboardLayout>
						{title || dashboard ? (
							<Helmet>
								<title>{title}</title>
							</Helmet>
						) : null}
						<Component />
					</DashboardLayout>
				) : <Component /> }
		</SecureRouteWrapper>
	);
};

export default DashboardRoute;
