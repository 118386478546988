import React from 'react';

import { ChatSettingsProviderProps } from '@common/react/components/Chat/ChatSettingsProvider';
import ChatSettingsProviderWithMorePlugins from '@common/react/components/Chat/ChatSettingsProviderWithMorePlugins';
import { getChatName } from '@common/react/components/Chat/ChatUtils';
import { ChatPlugins } from '@common/react/components/Chat/Chat';
import { VideoChatPlugin } from '@common/react/components/Chat/VideoChat/VideoChatPlugin';
import { VoiceCallPlugin } from '@common/react/components/Chat/VoiceCallPlugin/VoiceCallPlugin';
import { SearchingPlugin } from '@common/react/components/Chat/SearchingPlugin/SearchingPlugin';
import { ArchivePlugin } from '@common/react/components/Chat/ArchivePlugin/ArchivePlugin';
import { SendLater } from '@common/react/components/Chat/SendLater/SendLater';

import { Feature } from '@commonTuna/react/objects/CompanyFeature';

import { useCompanyFeatureProviderContext } from '@app/components/UI/CompanyFeaturesSetter/CompanyFeaturesSetter';

const SimpleChatSettingsProvider: React.FC<ChatSettingsProviderProps> = (props) => {
	const { checkFeatureAccess } = useCompanyFeatureProviderContext();
	const messagesHeaderComponents = props.messagesHeaderComponents
		?.filter((item) => (item !== ChatPlugins.VoiceCall && item !== ChatPlugins.VideoChat) || checkFeatureAccess(Feature.VideoChat));

	return <ChatSettingsProviderWithMorePlugins
		chatFormButtonsWrappers={{
			[ChatPlugins.VideoMessage]: (children) => checkFeatureAccess(Feature.VideoMessage) && children,
		}}
		renderChatName={({ currentChat, user }) => {
			const name = getChatName(currentChat, user.id);
			return <span title={Array.isArray(name) ? name.join(' ') : name}>{name}</span>;
		}}
		{...props}
		plugins={{
			[ChatPlugins.VideoChat]: VideoChatPlugin,
			[ChatPlugins.VoiceCall]: VoiceCallPlugin,
			[ChatPlugins.Searching]: SearchingPlugin,
			[ChatPlugins.Archive]: ArchivePlugin,
			[ChatPlugins.SendLater]: SendLater,
			...props.plugins,
		} as any}
		listComponent={[
			ChatPlugins.SendLater,
		]}
		messageAttachmentsBefore={[ChatPlugins.SendLater]}
		sendButtonWrapper={ChatPlugins.SendLater}
		chatListHeaderSettings={props.chatListHeaderSettings}
		messagesHeaderComponents={messagesHeaderComponents}
		showMessagesButtonInVideoModal
		title=""
	>
		{props.children}
	</ChatSettingsProviderWithMorePlugins>;
};

export default SimpleChatSettingsProvider;
