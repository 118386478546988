import { InitStorageAction as InitStorageItemAction, TypeKeys as ItemTypeKeys } from '@common/react/store/Item';
import { SetItemsAction, TypeKeys } from '@common/react/store/ItemsProviderStore';
import { SortingDirection } from '@common/react/components/Core/ItemsProvider/ItemsProvider';

import { CompanyFeature } from '@commonTuna/react/objects/CompanyFeature';
import { AppointmentStatus } from '@commonTuna/react/objects/AppointmentStatus';

import { AppointmentType } from '@commonTuna/react/objects/AppointmentType';

import { Init } from '@app/objects/Init';
import { HiddenAppointmentDots } from '@app/objects/HiddenAppointmentDots';
import { Location } from '@app/objects/Location';
import { UserFavorite } from '@app/objects/User';

export const customReduxActions = (dispatch, init: Init) => {
	const buildDataAction: InitStorageItemAction = {
		type: ItemTypeKeys.INITSTORAGE,
		storageName: 'buildData',
		item: {
			buildNumber: init.buildNumber,
			buildDate: init.buildDate,
			debug: init.debug,
			maxNumberLimit: init.maxNumberLimit,
			maxDescriptionLength: init.maxDescriptionLength,
			maxTextLength: init.maxTextLength,
			maxEmailSubjectLength: init.maxEmailSubjectLength,
			maxNameLength: init.maxNameLength,
			maxAddressLength: init.maxAddressLength,
			helpBotUserId: init.helpBotUserId,
		},
	};

	dispatch(buildDataAction);

	if (init.hostOptions) {
		const hostOptionsAction: InitStorageItemAction = {
			type: ItemTypeKeys.INITSTORAGE,
			storageName: 'hostOptions',
			item: init.hostOptions,
		};

		dispatch(hostOptionsAction);
	}

	if (init.facebookOptions) {
		const facebookOptionsAction: InitStorageItemAction = {
			type: ItemTypeKeys.INITSTORAGE,
			storageName: 'facebookOptions',
			item: init.facebookOptions,
		};

		dispatch(facebookOptionsAction);
	}

	if (init.companyFeatures) {
		const companyFeaturesAction: SetItemsAction<CompanyFeature> = {
			type: TypeKeys.SETITEMS,
			storageName: 'companyFeatures',
			items: init.companyFeatures,
			total: init.companyFeatures.length,
			objectType: 'companyFeature',
			params: {
				count: 100,
			},
		};

		dispatch(companyFeaturesAction);
	}

	if (init.companySettings) {
		const companySettingsAction = {
			type: ItemTypeKeys.INITSTORAGE,
			storageName: 'companySettings',
			item: init.companySettings,
		} as InitStorageItemAction;

		dispatch(companySettingsAction);
	}

	if (init.userFavorites) {
		const userFavoritesAction: SetItemsAction<UserFavorite> = {
			type: TypeKeys.SETITEMS,
			storageName: 'userFavorites',
			objectType: 'userFavorite',
			items: init.userFavorites,
			params: {
				count: 100,
				current: 1,
			},
		};

		dispatch(userFavoritesAction);
	}

	if (init.routesPropsPagesMap) {
		const routesPropsPagesMapAction = {
			type: ItemTypeKeys.INITSTORAGE,
			storageName: 'routesPropsPagesMap',
			item: init.routesPropsPagesMap,
		} as InitStorageItemAction;

		dispatch(routesPropsPagesMapAction);
	}

	if (init.enumAccesses) {
		const enumAccessesAction = {
			type: ItemTypeKeys.INITSTORAGE,
			storageName: 'enumAccesses',
			item: init.enumAccesses,
		} as InitStorageItemAction;

		dispatch(enumAccessesAction);
	}

	if (init.hiddenAppointmentDots) {
		const hiddenAppointmentDotsAction: SetItemsAction<HiddenAppointmentDots> = {
			type: TypeKeys.SETITEMS,
			storageName: 'hiddenAppointmentDots',
			objectType: 'hiddenAppointmentDots',
			items: init.hiddenAppointmentDots,
			params: {},
		};

		dispatch(hiddenAppointmentDotsAction);
	}

	if (init.userLocations) {
		const userLocationsAction: SetItemsAction<Location> = {
			type: TypeKeys.SETITEMS,
			storageName: 'locations',
			objectType: 'locations',
			items: init.userLocations,
			params: {
				count: 100,
				current: 1,
			},
		};

		dispatch(userLocationsAction);
	}

	if (init.appointmentStatuses) {
		const appointmentStatusesAction: SetItemsAction<AppointmentStatus> = {
			type: TypeKeys.SETITEMS,
			storageName: 'appointmentStatuses',
			objectType: 'appointmentStatuses',
			items: init.appointmentStatuses,
			params: {
				count: 100,
				current: 1,
				column: [{ caption: 'sortOrder', direction: SortingDirection.Ascending }],
			},
		};

		dispatch(appointmentStatusesAction);
	}

	if (init.appointmentTypes) {
		const appointmentTypesAction: SetItemsAction<AppointmentType> = {
			type: TypeKeys.SETITEMS,
			storageName: 'appointmentTypes',
			objectType: 'appointmentTypes',
			items: init.appointmentTypes,
			params: {
				count: 100,
				current: 1,
				column: [{ caption: 'sortOrder', direction: SortingDirection.Ascending }],
			},
		};

		dispatch(appointmentTypesAction);
	}
};
