import React from 'react';

import { OrderTargetType, OrderType } from '@app/objects/Order';
import { TargetType } from '@app/components/Pages/Admin/BaseReport/BaseReport';

export const OrderTargetTypeNames = {
	[OrderTargetType.Service]: 'Service',
	[OrderTargetType.Product]: 'Product',
	[OrderTargetType.Special]: 'Special',
	[OrderTargetType.Deposit]: 'Deposit',
	[OrderTargetType.Tip]: 'Tip',
	[OrderTargetType.Copay]: 'Copay',
	[OrderTargetType.Credit]: 'Credit',
	[OrderTargetType.None]: 'Payment',
};

const defaultOrderTargetTypes = [
	OrderTargetType.Service,
	OrderTargetType.Product,
	OrderTargetType.Special,
	OrderTargetType.Deposit,
	OrderTargetType.Tip,
];

const SPSDFilters = ({
	filters,
	handleChange,
	orderTargetTypes = defaultOrderTargetTypes,
}) => {
	const types = filters.orderTargetTypes as Array<OrderTargetType> || [];
	return (
		<>
			{orderTargetTypes.map((type) => (
				<span key={type} data-param="orderTargetTypes" className="filter-ratio-element">
					<button
						type="button"
						key={type}
						className={`${filters.orderTargetTypes?.some((q) => q === type) ? 'btn btn-primary' : 'btn btn-light'}`}
						onClick={(e) => {
							const selectedTypes = (types.some((q) => q === type)
								? types.filter((q) => q !== type)
								: types.concat(type)).filter((type) => orderTargetTypes.includes(type));

							const diff = orderTargetTypes.filter((t) => !selectedTypes.includes(t)).length > 0;

							handleChange({
								orderTargetTypes: selectedTypes,
								...(diff ? { targetType: TargetType.Targets } : {}),
							});
						}}
					>
						{OrderTargetTypeNames[type]}
					</button>
				</span>
			))}
		</>
	);
};

export default SPSDFilters;
