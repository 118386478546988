import * as React from 'react';

import Popover from 'antd/lib/popover';

import { ChatPlugin } from '@common/react/components/Chat/Chat';
import Datepicker, { disabledFrom } from '@common/react/components/Forms/Datepicker/Datepicker';
import Button from '@common/react/components/Forms/Button';

import '@common/react/scss/components/sendLaterPlugin.scss';
import { isSameDate, transformToUtc } from '@common/react/utils/timeUtils';

const times = [
	{
		hour: 9,
		label: 'Late today at 9:00 AM',
		today: true,
	},
	{
		hour: 17,
		label: 'Late today at 5:00 PM',
		today: true,
	},
	{
		hour: 9,
		label: 'Tomorrow at 9:00 AM',
	},
	{
		hour: 17,
		label: 'Tomorrow at 5:00 PM',
	},
];

const SendLaterDatePicker: React.FC<{formikBag, setOpen}> = (props) => {
	const {
		formikBag,
		setOpen,
	} = props;
	const [date, setDate] = React.useState<number | null>(null);
	return <div className="input-group">
		<Datepicker
			antdProps={{
				showTime: true,
				disabledTime: (day) => {
					const now = new Date();
					if (day && !isSameDate(now, new Date(+day))) {
						return {
							disabledHours: () => [],
							disabledMinutes: () => [],
						};
					}

					return {
						disabledHours: () => Array.from({ length: 24 }).map((_, i) => i).filter((h) => (h < now.getHours())),
						disabledMinutes: (selectedHour: number) => Array.from({ length: 60 }).map((_, i) => i)
							.filter((m) => (selectedHour < now.getHours() || selectedHour === -1)
								|| (selectedHour === now.getHours() && (m < now.getMinutes()))),
					};
				},
			}}
			disabledDate={disabledFrom(+new Date())}
			value={date}
			onChange={(date) => {
				if (date) {
					const d = new Date(+date);
					setDate(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), 0, 0));
				} else {
					setDate(null);
				}
			}}
		/>
		<div className="input-group-btn">
			<Button
				type="button"
				className="btn btn-primary"
				onClick={() => {
					formikBag.setFieldValue('sendingTime', date);
					formikBag.submitForm();
					setOpen(false);
					setDate(null);
				}}
				disabled={!date}
			>
				Submit
			</Button>
		</div>
	</div>;
};

const SendLaterComponent: React.FC<{formikBag, disabled}> = (props) => {
	const {
		formikBag, disabled,
	} = props;
	const [open, setOpen] = React.useState(false);
	return <Popover
		open={open}
		placement="topLeft"
		onOpenChange={setOpen}
		trigger="click"
		title="Send Later"
		content={<ul className="send-later-list">
			{times.map((time) => {
				const d = new Date();
				const resDate = +new Date(
					d.getFullYear(),
					d.getMonth(),
					d.getDate() + (time.today ? 0 : 1),
					time.hour,
					0,
					0,
					0,
				);
				return { ...time, time: resDate };
			})
				.filter((item) => item.time > +new Date())
				.slice(0, 2)
				.map((item) => <li
					className="send-later-list__item"
					onClick={() => {
						const d = new Date(item.time);
						const newTime = +new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), 0, 0);
						formikBag.setFieldValue('sendingTime', newTime);
						formikBag.submitForm();
						setOpen(false);
					}}
					key={item.time}
				>
					{item.label}
				</li>)
			}
			<li className="li-datepicker">
				<SendLaterDatePicker formikBag={formikBag} setOpen={setOpen} />
			</li>
		</ul>}
	>
		<Button type="button" className="btn btn-sm btn-primary" disabled={disabled}>
			<i className="fa fa-angle-down" />
		</Button>
	</Popover>;
};

const WillSent: React.FC<{ className?, time, showDate? }> = ({ className = 'send-later-time', time, showDate }) => {
	if (!time) return <></>;
	const date = new Date();
	date.setMilliseconds(0);
	date.setSeconds(0);
	const solvedTime = time + (transformToUtc(+date) - +date);
	if (solvedTime < +new Date()) return <></>;
	const solvedDate = new Date(solvedTime);
	const month = solvedDate.getUTCMonth() + 1;
	const day = solvedDate.getUTCDate();
	const hours = solvedDate.getUTCHours();
	const minutes = solvedDate.getUTCMinutes();
	const amPm = hours < 12 || hours === 24 || (hours === 0 && minutes === 0) ? 'AM' : 'PM';

	return <>
		<div className="send-later-time-bg" />
		<span className={className}>
			will be send at
			{' '}
			{!isSameDate(new Date(solvedTime), date) || showDate
				? `${month < 10 ? '0' : ''}${month}/${day < 10 ? '0' : ''}${day}/${solvedDate.getUTCFullYear()} ` : ''}
			{`${hours > 12 ? hours - 12 : hours}:${minutes < 10 ? '0' : ''}${minutes} ${amPm}`}
		</span>
	</>;
};

export const SendLater: ChatPlugin = {
	sendButtonWrapper: (button, formikBag, disabled) => {
		return <div className="send-later-wrapper">
			{button}
			<SendLaterComponent formikBag={formikBag} disabled={disabled} />
		</div>;
	},
	listComponent: ({ chat }) => <WillSent time={chat?.lastMessage?.sendingTime} />,
	messageAttachmentBefore: (message) => <WillSent
		className="chat-message-list-component__item-send-later"
		time={message?.sendingTime}
		showDate
	/>,
};
