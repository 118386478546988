import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { bindActionCreators } from 'redux';

import RegularChatMessage from '@common/react/components/Chat/RegularMessagePlugin/RegularChatMessage';
import { ChatMessage } from '@common/react/components/Chat/Chat';
import '@common/react/scss/components/forwardedMessage.scss';
import Button from '@common/react/components/Forms/Button';
import { getActionCreators } from '@common/react/components/Chat/Store/Chats';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import { replaceSearchUrl } from '@common/react/components/Utils/Utils';
import { File as FileInterface } from '@common/typescript/objects/FileInterface';
import { imageExtensions } from '@common/react/components/Chat/Chats';

interface ReplyMessageProps {
	message: ChatMessage;
	contacts: Array<any>;
}

const ReplyMessage: React.FC<ReplyMessageProps> = ({ message, contacts }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const chat = useSelector((state: any) => state.chats.chats.list.find((chat) => chat.id === message.innerChatMessage?.chatId));
	const actions = React.useMemo(() => bindActionCreators(getActionCreators(), dispatch), []);
	const {
		state: {
			storageName, plugins, otherComponents, basePath,
		},
	} = useChatSettingsProviderContext();
	const [img, setImg] = React.useState<FileInterface | undefined>();
	const images = React.useMemo(() => {
		const files: Array<FileInterface> = [];
		[...(message.innerChatMessage?.files || [])]
			.reverse()
			.forEach((f) => (imageExtensions.includes(f.file.extension?.toLowerCase()) ? files.push(f) : ''));
		return files;
	}, [message.files]);
	const imageIndex = React.useMemo(() => {
		const index = images.findIndex((image) => image.file.id === img?.file.id);
		return index < 0 ? null : index;
	}, [images, img]);

	const handleIconClick = (e, file: FileInterface) => {
		e.preventDefault();
		setImg(file);
	};

	const handleMessage = () => {
		actions.selectChat(chat, storageName);
		navigate({
			...location,
			search: replaceSearchUrl(location.search, 'messageId', `${message.innerChatMessageId}`),
		});
	};

	return (
		<div>
			<React.Fragment key="colorbox">
				{otherComponents?.map((plugin) => {
					const props = {
						chat, defaultIdx: imageIndex, setImg, files: images, basePath,
					};
					const component = typeof plugin === 'function' ? plugin(props)
						: plugins[plugin]?.additionalComponent?.({
							chat, defaultIdx: imageIndex, setImg, files: images, basePath,
						});

					return <React.Fragment key={`${plugin}`}>
						{component}
					</React.Fragment>;
				})}
			</React.Fragment>
			<div className="forwarded-message">
				<div className="forwarded-message__info">
					<i className="fa fa-reply" />
				</div>
				<div className="forwarded-message__content">
					{
						message.innerChatMessage
							? plugins[message.innerChatMessage.chatMessageType]?.message?.render({
								message: message.innerChatMessage
									? { ...message.innerChatMessage, innerChatMessage: null } : null,
								contacts,
								withRemoteId: undefined,
								onImageClick: handleIconClick,
								onMouseEnter: undefined,
								lastVideoCallId: undefined,
							})
							: <div className="empty-message">Message is not found</div>
					}
					{
						chat
						&& <Button onClick={handleMessage} className="message-action btn btn-primary btn-xs">
							To Message
						</Button>
					}
				</div>
			</div>
			<RegularChatMessage message={message} contacts={contacts} />
		</div>
	);
};

export default ReplyMessage;
