import * as React from 'react';

import Button from 'antd/lib/button';

import { ChatRoomType, ChatPlugin } from '@common/react/components/Chat/Chat';
import VideoChatButton from '@common/react/components/UI/VideoChat/VideoChatButton';
import CustomButton from '@common/react/components/Forms/Button';

const Message = ({
	message, withRemoteId, lastVideoCallId, contacts,
}) => <>
	<div className="chat-message-list-component__item-call-button">
		{
			message.chatRoomType === ChatRoomType.Video
				? (
					<VideoChatButton
						chatId={message.chatId}
						notifyUserIds={contacts.map((item) => item.id)}
						roomId={message.text}
						roomName={withRemoteId ? message.chat?.name ?? 'Unknown' : message.text.split('_')[1]}
						roomCreateActor={withRemoteId ? 'videoChatTuna' : 'videoChat'}
						button={(handleClick, isDisabled, loading) => (
							<Button
								htmlType="button"
								shape="circle"
								loading={loading}
								onClick={handleClick}
								disabled={lastVideoCallId !== message.id || isDisabled}
							>
								<i className="fa fa-video-camera" />
							</Button>
						)}
					/>
				)
				: (
					<VideoChatButton
						isVideo={false}
						notifyUserIds={contacts.map((item) => item.id)}
						roomId={message.text}
						chatId={message.chatId}
						roomName={withRemoteId ? message.chat?.name ?? 'Unknown' : message.text.split('_')[1]}
						roomCreateActor={withRemoteId ? 'videoChatTuna' : 'videoChat'}
						button={(handleClick, isChatting, loading) => (
							<Button
								htmlType="button"
								shape="circle"
								loading={loading}
								onClick={handleClick}
								disabled={lastVideoCallId !== message.id || isChatting}
							>
								<i className="fa fa-phone-square" />
							</Button>
						)}
					/>
				)
		}
	</div>
</>;

const LastMessage = ({ message }) => <div className="chat-list__item-last-message">
	<i className={`fa fa-${message.chatRoomType === ChatRoomType.Video ? 'video-camera' : 'phone-square'}`} style={{ color: 'red' }} />
</div>;

const messagesHeaderAction = ({
	withRemoteId, currentChat, user, navigate, patientId,
}) => {
	const userCall = currentChat.contacts.find((item) => (withRemoteId ? item.remoteId === null : item.id !== user.id));
	return currentChat.contacts.length <= 2 && <VideoChatButton
		roomCreateActor={withRemoteId ? 'videoChatSupport' : 'videoChat'}
		roomId={`${currentChat.id}_${currentChat.name}`}
		roomName={`${userCall?.firstName} ${userCall?.lastName}`}
		chatId={currentChat.id}
		notifyUserIds={withRemoteId ? [user.id, userCall.id] : currentChat.contactsIds}
		avatar={userCall?.avatar}
		button={(onClick, isDisabled, loading) =>
			<CustomButton
				isLoading={loading}
				className="btn btn-sm btn-primary pull-right"
				onClick={onClick}
				disabled={isDisabled}
				title={withRemoteId ? 'Video call to the patient' : 'Video Call'}
			>
				<i className="fa fa-video-camera" />
			</CustomButton>}
	/>;
};

export const VideoChatPlugin: ChatPlugin = {
	message: {
		render: Message,
		lastMessage: LastMessage,
	},
	messagesHeaderAction,
};
