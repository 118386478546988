import React from 'react';
import {
	useLocation, useNavigate, NavigationType, UNSAFE_LocationContext, UNSAFE_NavigationContext,
} from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import once from 'lodash/once';

import Sizable from '@common/react/components/UI/Sizable/Sizable';
import ServerPageProvider from '@common/react/components/Core/ServerPageProvider/ServerPageProvider';
import { parseQuery } from '@common/typescript/utils/url';
import { BaseParams } from '@common/typescript/objects/BaseParams';
import { addPrefix, filterByPrefix, getKeysByPrefix } from '@common/react/utils/ObjectKeysPrefix/objectKeysPrefix';
import {
	getSearchParamsFromUrl,
	urlHandleItems,
} from '@common/react/utils/FIltersParamsFromUrl/FiltersParamsFromUrl';

interface SizableWithServerPageProps {
	urlKey: string;
	getRightServerPageState?: (state) => any;
	sizableClassName?: string;
	additionalParams?: BaseParams;
	getSearchFromParams?: (params: BaseParams, key?: string) => BaseParams;
	rightComponent?: Array<{ path, component }>;
	innerHistoryListen?: (to, prefix, mainLocation, navigate, state) => void;
	getPathnamePostfix?: (pathname: string, params: BaseParams, urlKey: string) => string;
}

const defaultGetRightServerPageState = (state) =>
	({ page: state.serverPages?.pages?.length > 1 ? state.serverPages.pages[1] : null, path: 'right-tabs' });

export interface SizableProviderContext {
	state: object;
}

export const createSizableProviderContext = once(() => React.createContext({} as SizableProviderContext));

export const useSizableProviderContext: () => SizableProviderContext = () =>
	React.useContext(createSizableProviderContext());

const defaultGetSearchFromParams: (BaseParams, key) => string = (params, key) => {
	if (!key) {
		return '';
	}
	const searchObj = { id: params.id, ...getKeysByPrefix(params, `${key}-`) };
	const emptyValues = ['', 'undefined', '-1', undefined, -1];

	return Object.keys(searchObj)
		.filter((k) => emptyValues.indexOf(searchObj[k]) === -1)
		.map((k) => urlHandleItems(searchObj, k, 0)).join('&');
};

const defaultGetPathnamePostfix = (pathname, params, urlKey) => {
	if (!pathname) return '';

	const pageOrId = params.id || params[`${urlKey}-id`] || params[`${urlKey}-page`];
	return pageOrId ? `/${pageOrId}` : '';
};

const getPath = (location) => {
	return `${location.pathname}${location.search ? `?${location.search}` : ''}`;
};

export const defaultInnerHistoryListen = (to, prefix, mainLocation, navigate, state) => {
	const searchString = typeof to !== 'string' ? to.search : to?.split('?')?.[1];
	const id = (typeof to === 'string' ? to : to.pathname)?.match(/\/-?(\d+)/)?.[1];
	const leftParams = getSearchParamsFromUrl(mainLocation);
	const empty = filterByPrefix(leftParams, prefix);
	Object.keys(empty).forEach((key) => empty[key] = undefined);

	const searchObj = {
		...getSearchParamsFromUrl(mainLocation),
		...empty,
		...addPrefix(parseQuery(searchString), prefix),
		id,
		[`${prefix}page`]: id,
	};

	const emptyValues = ['', 'undefined', '-1', undefined, -1];
	const search = Object.keys(searchObj)
		.filter((k) => emptyValues.indexOf(searchObj[k]) === -1)
		.map((k) => urlHandleItems(searchObj, k, 0)).join('&');
	console.log(mainLocation, search);
	navigate({
		...mainLocation,
		search,
	}, {
		state: { ...state, rightUpdate: true },
		replace: true,
	});
};

const SizableWithServerPage: React.FC<SizableWithServerPageProps> = ({ children, ...props }) => {
	const Context = createSizableProviderContext();
	const context = useSizableProviderContext();
	const {
		urlKey, rightComponent, getRightServerPageState = defaultGetRightServerPageState, sizableClassName, additionalParams,
		getSearchFromParams = defaultGetSearchFromParams,
		innerHistoryListen = defaultInnerHistoryListen,
		getPathnamePostfix = defaultGetPathnamePostfix,
	} = props;
	const location = useLocation();
	const navigate = useNavigate();
	const navigator = React.useContext(UNSAFE_NavigationContext).navigator;
	const params = parseQuery(location.search);
	const [key, setKey] = React.useState<string | undefined>(() => params[urlKey]);
	const serverPages = useSelector((state: any) => state.serverPages, shallowEqual);

	const showRightComponent = React.useMemo(() => {
		return !context.state && typeof params[urlKey] !== 'undefined' && typeof key !== 'undefined';
	}, [params?.[urlKey], key, context.state]);
	const newLocation = React.useMemo(() => ({
		...location,
		pathname: `/${key}${getPathnamePostfix(`${key}`, params, urlKey)}`,
		search: getSearchFromParams(params, urlKey) as string,
	}), [key, location]);
	const path = React.useMemo(() => getPath(newLocation), [newLocation]);

	React.useEffect(() => {
		if (location?.state?.rightUpdate) {
			location.state.rightUpdate = false;
		}
	}, [newLocation]);

	React.useEffect(() => {
		const newActiveKey = params[urlKey];

		if (newActiveKey !== key) {
			setKey(newActiveKey);
		}
	}, [location]);

	React.useEffect(() => {
		if (location?.state?.rightUpdate) {
			location.state.rightUpdate = false;
		}
	}, [location]);

	React.useEffect(() => {
		if (serverPages?.pages) {
			serverPages.pages = null;
		}
	}, []);

	return <Context.Provider value={{ state: {} }}>
		<Sizable
			className={sizableClassName}
			showRightComponent={showRightComponent}
			leftComponent={children}
			rightComponent={
				/* eslint-disable */
				<UNSAFE_LocationContext.Provider
					value={{
						location: newLocation,
						navigationType: NavigationType.Replace,
					}}
					key={urlKey}
				>
					<UNSAFE_NavigationContext.Provider
						value={{
							basename: '/',
							navigator: {
								...navigator,
								replace(to, state, opts) {
									innerHistoryListen(to, `${urlKey}-`, location, navigate, state);
								},
							},
							static: false,
						}}
					>
						<ServerPageProvider
							getInnerPageFromContext
							transformToItem={(item) => {
								return item ? { ...item, innerPage: item } : undefined;
							}}
							additionalParams={{
								...additionalParams,
								path,
								rightKey: urlKey,
							}}
							getState={getRightServerPageState}
							inner={() => {
								const Component = rightComponent?.find(({ path }) => path.includes(key))?.component;
								return Component ? <Component /> : null;
							}}
						/>
					</UNSAFE_NavigationContext.Provider>
				</UNSAFE_LocationContext.Provider>
			}
		/>
	</Context.Provider>;
};

export default SizableWithServerPage;
