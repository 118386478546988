import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { BaseUserWithAvatar } from '@common/react/objects/BaseUser';
import { getUserName, getUserNameWithEmail } from '@common/react/utils/utils';
import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import { ChatKind, ChatMessage, ChatPlugins } from '@common/react/components/Chat/Chat';
import AddUserToChatPopup from '@common/react/components/Chat/AddUserToChatPlugin/AddUserToChatPopup';
import '@common/react/scss/components/chats.scss';
import AddChatPopup from '@common/react/components/Chat/AddChatPlugin/AddChatPopup';
import { useStickerProviderContext } from '@common/react/components/Chat/Sticker/StickerProvider';
import { GiphyPlugin } from '@common/react/components/Chat/Giphy/GiphyPlugin';
import { StickerPlugin } from '@common/react/components/Chat/Sticker/StickerPlugin';
import { DeletePlugin } from '@common/react/components/Chat/DeletePlugin/DeletePlugin';
import { EditPlugin } from '@common/react/components/Chat/EditPlugin/EditPlugin';
import { ReactionsPlugin } from '@common/react/components/Chat/Reactions/ReactionsPlugin';
import { ArchivePlugin } from '@common/react/components/Chat/ArchivePlugin/ArchivePlugin';
import { SearchingPlugin } from '@common/react/components/Chat/SearchingPlugin/SearchingPlugin';
import { LeaveChatPlugin } from '@common/react/components/Chat/LeaveChatPlugin/LeaveChatPlugin';
import { ChatNameEditorPlugin } from '@common/react/components/Chat/ChatNameEditorPlugin/ChatNameEditorPlugin';
import { CompleteChatPlugin } from '@common/react/components/Chat/CompleteChatPlugin/CompleteChatPlugin';
import { VoiceCallPlugin } from '@common/react/components/Chat/VoiceCallPlugin/VoiceCallPlugin';
import { MentionPlugin } from '@common/react/components/Chat/MentionPlugin/MentionPlugin';
import { TenorPlugin } from '@common/react/components/Chat/TenorPlugin/TenorPlugin';
import { ForwardPlugin } from '@common/react/components/Chat/ForwardPlugin/ForwardPlugin';
import { CopyPlugin } from '@common/react/components/Chat/CopyPlugin/CopyPlugin';
import { RegularMessagePlugin } from '@common/react/components/Chat/RegularMessagePlugin/RegularMessagePlugin';
import { ModalPlugin } from '@common/react/components/Chat/ModalPlugin/ModalPlugin';
import { ReplyPlugin } from '@common/react/components/Chat/ReplyPlugin/ReplyPlugin';
import { TemplatePlugin } from '@common/react/components/Chat/TemplatePlugin/TemplatePlugin';
import { AdaptiveCardsPlugin } from '@common/react/components/Chat/AdaptiveCards/AdaptiveCards';
import { VoiceMessagePlugin } from '@common/react/components/Chat/VoiceMessage/VoiceMessagePlugin';
import Avatar from '@common/react/components/Forms/Avatar';

import { Feature } from '@commonTuna/react/objects/CompanyFeature';
import { UserGroupType } from '@commonTuna/react/objects/UserGroup';

import { User } from '@app/objects/User';
import '@app/scss/pages/chatsPage.scss';
import { useCompanyFeatureProviderContext } from '@app/components/UI/CompanyFeaturesSetter/CompanyFeaturesSetter';
import UserAvatarWithStatus from '@app/components/UI/UserAvatarWithStatus';
import ChatMembers, { PopupMemberItem } from '@app/components/Pages/Admin/Chats/ChatMembers';
import SimpleChatSettingsProvider
	from '@app/components/Pages/Admin/Chats/SimpleChatSettingsProvider/SimpleChatSettingsProvider';
import { useUserGroupContext } from '@app/components/UI/UserGroupProvider/UserGroupProvider';
import MedicalTranscribeButton from '@app/components/Pages/Admin/Chats/MedicalTranscribeButton';

export const getUserPick = (chat, userId): JSX.Element => {
	const users = chat.contacts.filter((user: BaseUserWithAvatar) => user.id !== userId);
	const user = users[0] ?? chat.contacts[0];
	if (users.length === 1 || chat.contacts.length === 1) {
		return (
			<div
				className="chat-list__item-avatar"
				title={getUserName(user)}
			>
				<UserAvatarWithStatus
					onClick={() => undefined}
					user={user as User}
				/>
			</div>
		);
	}

	return (
		<div className="chat-list__item-avatar-multiple">
			{users.slice(0, 4).map((user: BaseUserWithAvatar & {color?: string}) => (
				<div
					className="chat-list__item-avatar chat-list__item-avatar_min"
					key={user.id}
					title={getUserName(user)}
				>
					<UserAvatarWithStatus
						onClick={() => undefined}
						size={24}
						user={user as User}
					/>
				</div>
			))}
		</div>
	);
};

export const getUserAvatar = (user, portal?: boolean, size: number = 36) => (
	<div className="chat-message-list-component__item-avatar">
		<UserAvatarWithStatus
			onClick={() => undefined}
			user={{ ...user, avatar: portal ? `/remote/${user.avatar}` : user.avatar }}
			size={size}
		/>
	</div>
);

export const notificationAvatar = (state) => (
	<Avatar
		type=""
		object={state}
		readonly
		className="avatar-container"
		thumbClassName=""
		showOpenIcon={false}
		showDownload={false}
		withModal={false}
		firstName={state.firstName ?? 'N'}
		lastName={state.lastName ?? 'N'}
	/>
);

export const ViewerAvatar = (messageViewer) => (
	<Avatar
		type=""
		object={messageViewer?.user || {}}
		readonly
		className="avatar-container"
		thumbClassName=""
		showOpenIcon={false}
		showDownload={false}
		withModal={false}
		color={messageViewer?.user?.color}
		firstName={messageViewer?.user?.firstName ?? 'N'}
		lastName={messageViewer?.user?.lastName ?? 'N'}
	/>
);

export const SystemChatSettingsProvider: React.FC = ({ children }) => {
	const navigate = useNavigate();
	const context = useStickerProviderContext();
	const collections = context?.state?.collections;
	const skeleton = context?.state?.skeleton || '';
	const { checkUserGroupAccess } = useUserGroupContext();
	const { checkFeatureAccess } = useCompanyFeatureProviderContext();

	GiphyPlugin.options = { skeleton, giphyKey: 'YN5zYP1hVQ1jNCARuVUtVvHplmtZq0Ub' };
	StickerPlugin.options = { skeleton, collections };
	TenorPlugin.options = { apiKey: 'AIzaSyDbYLhLfhX0UQBH1i0tkL-vzBLqfpbu7AE', skeleton };
	VoiceMessagePlugin.options = {
		transcribeButton: (source: string, chatMessage: ChatMessage) => {
			if (source && checkFeatureAccess(Feature.MedicalTranscribe)) {
				return <MedicalTranscribeButton
					path={source}
					objectId={chatMessage.id}
					objectType="ChatMessage"
					objectField="text"
					chatMessage={chatMessage}
					data={{ chatId: `${chatMessage.chatId}` }}
				/>;
			}

			return null;
		},
	};
	const mentionPlugin = React.useMemo(() => {
		return {
			...MentionPlugin,
			options: {
				onMentionClick: (contact: BaseUserWithAvatar) => navigate(`/user-editor/${contact.id}`),
			},
		};
	}, []);
	/* const voiceMessagePlugin = React.useMemo(() => {
		return {
			...VoiceMessagePlugin,
			options: {
				transcribeButton: 456,
			},
		};
	}, []); */ // Add check company feature
	const templatePlugin = React.useMemo(() => {
		return {
			...TemplatePlugin,
			options: {
				options: [
					'user_firstName',
					'user_lastName',
				],
				getData: (chat, user) => {
					const otherUser = chat.contacts.filter((q) => q.id !== user.id)[0];
					/* eslint-disable */
					// @ts-ignore
					return {
						user_firstName: otherUser?.firstName || '',
						user_lastName: otherUser?.lastName || '',
					};
					/* eslint-enable */
				},
			},
		};
	}, []);

	const emptyChatListMessage = React.useMemo(() => ({ filters }) => {
		if (filters?.archive) {
			return 'No archive chats';
		}
		return 'There are no chats';
	}, []);

	return (
		<SimpleChatSettingsProvider
			formSettings={{
				sendByEnter: false,
				underFormLabel: '',
				allowPasteImages: true,
				maxAttachmentsCount: 5,
				maxMessageLength: 1000,
			}}
			chatsFilters={{
				archive: false,
			}}
			chatFormButtonsWrappers={{
				[ChatPlugins.VideoMessage]: (children) => checkFeatureAccess(Feature.VideoMessage) && children,
			}}
			avatarSettings={{
				getChatAvatar: getUserPick,
				getUserAvatar,
				getUserAvatarAtMention: (user) => getUserAvatar(user, false, 30),
				notificationAvatar,
				viewerAvatar: ViewerAvatar,
			}}
			onMessageDoubleClick={(e, message, reply) => {
				e.preventDefault();
				reply(message);
			}}
			openInModal={!checkUserGroupAccess(UserGroupType.ShowChatInModal)}
			plugins={{
				[ChatPlugins.Regular]: RegularMessagePlugin,
				[ChatPlugins.UserAttached]: RegularMessagePlugin,
				[ChatPlugins.UserDetached]: RegularMessagePlugin,
				[ChatPlugins.Giphy]: GiphyPlugin,
				[ChatPlugins.Sticker]: StickerPlugin,
				[ChatPlugins.Deleted]: DeletePlugin,
				[ChatPlugins.Edit]: EditPlugin,
				[ChatPlugins.Reactions]: ReactionsPlugin,
				[ChatPlugins.Searching]: SearchingPlugin,
				[ChatPlugins.Archive]: ArchivePlugin,
				[ChatPlugins.LeaveChat]: LeaveChatPlugin,
				[ChatPlugins.ChatNameEditor]: ChatNameEditorPlugin,
				[ChatPlugins.CompleteChat]: CompleteChatPlugin,
				[ChatPlugins.VoiceCall]: VoiceCallPlugin,
				[ChatPlugins.Mentions]: mentionPlugin,
				[ChatPlugins.Tenor]: TenorPlugin,
				[ChatPlugins.Forward]: ForwardPlugin,
				[ChatPlugins.Copy]: CopyPlugin,
				[ChatPlugins.ModalPlugin]: ModalPlugin,
				[ChatPlugins.Reply]: ReplyPlugin,
				[ChatPlugins.Template]: templatePlugin,
				[ChatPlugins.AdaptiveCards]: AdaptiveCardsPlugin,
				[ChatPlugins.VoiceMessage]: VoiceMessagePlugin,
			}}
			messageAttachments={[ChatPlugins.AdaptiveCards]}
			messageActions={[
				ChatPlugins.Copy,
				ChatPlugins.Forward,
				ChatPlugins.Reply,
				ChatPlugins.Edit,
				ChatPlugins.Deleted,
				ChatPlugins.Reactions,
			]}
			chatListHeaderSettings={[
				({ user, selectChat }) => (
					<AddChatPopup
						user={user}
						onSave={selectChat}
						setChatKind={(contacts, kind) => (
							contacts.some((c) => c.botType) ? ChatKind.Bot : kind
						)}
						render={(show) => (
							<button type="button" className="btn btn-primary" title="Add Chat" onClick={show}>
								<i className="fa fa-plus" />
							</button>
						)}
						type="userChatViewList"
						renderItem={(user: BaseUserWithAvatar) => (
							<div className="add-user-item">
								<div className="pull-left mr10">
									<UserAvatarWithStatus size={25} user={user as User} />
								</div>
								<span
									className="add-user-item__autocomplete"
									style={{ lineHeight: '25px' }}
								>
									{getUserNameWithEmail(user)}
								</span>
								<LinkWithPrevLocation
									className="add-user-item__tag"
									to={{ pathname: `/user-editor/${user.id}`, search: 'mode=view' }}
									style={{ lineHeight: '25px' }}
								>
									{getUserName(user)}
								</LinkWithPrevLocation>
							</div>
						)}
					/>
				),
				ChatPlugins.Searching,
				ChatPlugins.Archive,
			]}
			messagesHeaderComponents={[
				ChatPlugins.VoiceCall,
				ChatPlugins.VideoChat,
				ChatPlugins.CompleteChat,
				ChatPlugins.LeaveChat,
				ChatPlugins.ChatNameEditor,
				ChatPlugins.ModalPlugin,
				({ currentChat, user }) => currentChat.kind === ChatKind.Group && currentChat.userId === user.id && (
					<AddUserToChatPopup
						user={user}
						chat={currentChat}
						render={(show) => (
							<button
								type="button"
								className="btn btn-sm btn-primary pull-right"
								title="Add user to chat"
								onClick={show}
							>
								<i className="fa fa-plus" />
							</button>
						)}
						type="userChatViewList"
						autocompleteParams={{
							excludeUserIds: currentChat?.contacts?.filter((p) => !p.deleted)?.map((user) => user.id),
						}}
						renderItem={(user: BaseUserWithAvatar) => (
							<PopupMemberItem key={user.id} user={user} chatId={currentChat.id} />
						)}
						renderAutocompleteItem={(user: BaseUserWithAvatar) =>
							<PopupMemberItem key={user.id} user={user} />}
					/>
				),
				({ currentChat }) => (
					<div className="pull-left mr10" style={{ lineHeight: '30px' }}>
						<ChatMembers currentChat={currentChat} />
					</div>
				),
			]}
			formButtons={[
				ChatPlugins.Tenor,
				ChatPlugins.Giphy,
				ChatPlugins.Sticker,
				ChatPlugins.VideoMessage,
				ChatPlugins.VoiceMessage,
				ChatPlugins.Files,
				ChatPlugins.Emoji,
				ChatPlugins.Template,
			]}
			emptyChatListMessage={emptyChatListMessage}
		>
			{children}
		</SimpleChatSettingsProvider>
	);
};
