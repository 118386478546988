import * as React from 'react';
import { Outlet } from 'react-router-dom';

import ErrorBoundaryWithSentry from '@common/react/components/UI/ErrorBoundaryWithSentry/ErrorBoundaryWithSentry';
import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';

import { MainLayout } from '@app/components/Layouts/MainLayout';

export const MainWrapper: React.FC = () => (
	<MainLayout>
		<ErrorBoundaryWithSentry>
			<RequestProvider>
				<Outlet />
			</RequestProvider>
		</ErrorBoundaryWithSentry>
	</MainLayout>
);
