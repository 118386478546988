import * as React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { bindActionCreators } from 'redux';

import memoize from 'lodash/memoize';

import { getActionCreators, State } from '@common/react/store/SwitcherStore';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import { NotificationAction } from '@common/typescript/objects/NotificationAction';

export interface SwitcherProviderProps<T> {
	/**
	 * property in redux that stores data for SwitcherProvider.
	 */
	storeName: string;
	/**
	 * - 1. ReactElement to be wrapped in an ItemsProvider context
	 * - 2. function with context ItemsProvider as first argument
	 */
	children?: React.ReactNode | ((context: SwitcherProviderContext<T>) => React.ReactNode);
	/**
	 * the key for which a separate context will be created. default is storeName
	 * @default storeName
	 */
	type?: string;
	/**
	 * type of notification from which data about changes to one element will come
	 */
	notificationObjectType?: string;
	/**
	 * function to get element from notification
	 * @param notificationData
	 */
	getObjectFromNotification?: (notificationData: any) => T;
	/**
	 * type of notification from which data about changes in several elements will come
	 */
	notificationListObjectType?: string;
	/**
	 * function to get elements from notification
	 * @param notificationData
	 */
	getObjectListFromNotification?: (notificationData: any) => Array<{objectId: number, value: T}>;
	/**
	 * identifier by which the element will be tracked.
	 * after rendering the value will be added to the store
	 */
	id?: number;
	/**
	 * determines whether the element's tracking will be reset after the element is unmounted
	 */
	preventSubtract?: boolean;
	/**
	 * The value that will be placed in the store. After the change, updates the state in the store
	 */
	value?: any;
}

export interface SwitcherProviderContextState<T> {
	/**
	 * an array of objects containing objectId, count and value.
	 *
	 * objectId - identifier by which the element will be tracked.
	 *
	 * count - a value that determines how many UI elements listen for value changes.
	 *
	 * value - current value corresponding to id. May be undefined if the value has not changed since display
	 */
	items: State<T>;
	/**
	 * value corresponding to id from props.
	 */
	item?: T;
	/**
	 * function to get element from items by objectId
	 * @param objectId
	 */
	getItem: (objectId: number) => T | undefined;
}

export interface SwitcherProviderContext<T> {
	state: SwitcherProviderContextState<T>;
	actions: {
		/**
		 * function that adds identifiers for tracking. If the element is already being tracked, then adds one to the count of the corresponding id
		 * @param id
		 */
		add: (id: number) => void,
		/**
		 * a function that decreases the count for the corresponding id. If the final count is less than or equal to zero,
		 * then the value for id is removed from the store and will not be tracked
		 * @param id
		 */
		subtract: (id: number) => void,
		/**
		 * function for changing the value in an object with the passed id
		 * @param id
		 * @param value - new value
		 */
		change: (id: number, value: T) => void,
		/**
		 * function for changing the state of several objects. If the object did not exist, it will be added with count equal to 1
		 * @param list - an array of elements that need to be changed or added to the store
		 */
		updateList: (list: Array<{ value: T, objectId: number}>) => void,
	};
}

export const createSwitcherProviderContext = memoize((type?: string) => {
	return React.createContext({} as SwitcherProviderContext<any>);
});

export const useSwitcherProviderContext: <T = any>(type: string) => SwitcherProviderContext<T> = (type: string) =>
	React.useContext(createSwitcherProviderContext(type));

/**
 * SwitcherProvider component.
 *
 * The component is used to obtain the current value of some entity in different places in the application. For example, user status online/offline.
 *
 * usage examples:
 *  - <SwitcherProvider storeName="someType">{React.ReactNode}</SwitcherProvider>
 *  - <SwitcherProvider storeName="someType">{(context) => React.ReactNode}</SwitcherProvider>
 *  - <SwitcherProvider storeName="someType" />
 *
 * @typeParam T - T Any {WithKey}
 * @param props - SwitcherProviderProps
 * @type {React.FC<SwitcherProviderProps<T>>}
 * @returns React.ReactElement
 */
// eslint-disable-next-line
const SwitcherProvider = <T extends any = any, >(props: SwitcherProviderProps<T>) => {
	const {
		storeName,
		type = storeName,
		children,
		notificationObjectType: notificationObjectTypeProps,
		getObjectFromNotification,
		notificationListObjectType,
		getObjectListFromNotification,
		id,
		value: propsValue,
		preventSubtract,
	} = props;
	const [notificationObjectType] = React.useState(notificationObjectTypeProps);
	const Context = createSwitcherProviderContext(type);
	const dispatch = useDispatch();
	const items = useSelector((state) => state[storeName], shallowEqual);
	const {
		add,
		subtract,
		change,
		updateList,
	} = React.useMemo(() => bindActionCreators(
		getActionCreators<T>(type),
		dispatch,
	), [dispatch]);
	const { subscribeUntilLogout } = useApplicationContext();
	const getItem = (objectId: number) => {
		return items.find((value) => value.objectId === objectId)?.value;
	};

	if (notificationObjectType || notificationListObjectType) {
		const handle = (notification) => {
			if (notification.objectType === notificationObjectType && getObjectFromNotification) {
				const data = notification.data;
				if (data && notification.action === NotificationAction.Update) {
					change(data.id, getObjectFromNotification(data));
				}
			}

			if (notification.objectType === notificationListObjectType && notification.data && getObjectListFromNotification) {
				updateList(getObjectListFromNotification(notification.data));
			}
		};

		subscribeUntilLogout(handle);
	}

	const item = React.useMemo(() => {
		return id ? getItem(id) : undefined;
	}, [items, id]);

	React.useEffect(() => {
		if (id) {
			add(id);
			return () => {
				!preventSubtract && subtract(id);
			};
		}
	}, []);

	React.useEffect(() => {
		if (id && propsValue !== undefined) {
			change(id, propsValue);
		}
	}, [propsValue]);

	const value = {
		state: {
			items,
			getItem,
			item,
		},
		actions: {
			add,
			subtract,
			change,
			updateList,
		},
	};

	return (
		<Context.Provider value={value}>
			{typeof children === 'function' ? children(value) : children}
		</Context.Provider>
	);
};

export default SwitcherProvider;
