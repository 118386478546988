import * as React from 'react';
import { Outlet } from 'react-router-dom';

import ErrorBoundaryWithSentry from '@common/react/components/UI/ErrorBoundaryWithSentry/ErrorBoundaryWithSentry';
import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';

import { MainLayout } from '@app/components/Layouts/MainLayout';
import Footer from '@app/components/UI/Footer/Footer';
import Header from '@app/components/UI/Header/Header';
import '@app/scss/pages/openPage.scss';
import '@app/scss/pages/privacyPolicy.scss';

interface Props {
	component?: any;
	withHeader?: boolean;
}

const OpenPageWrapper: React.FC<Props> = ({ component: Component, ...rest }) => (
	<MainLayout>
		<ErrorBoundaryWithSentry>
			<RequestProvider>
				{rest.withHeader && <Header />}
				{Component ? <Component /> : <Outlet />}
				<Footer />
			</RequestProvider>
		</ErrorBoundaryWithSentry>
	</MainLayout>
);

export default OpenPageWrapper;
