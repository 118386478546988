import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import Button from '@common/react/components/Forms/Button';
import { RequestButton } from '@common/react/components/UI/LoadingButton/LoadingButton';

import {
	AppointmentInsurance,
	AppointmentInsuranceStatusesInfo,
} from '@app/objects/AppointmentInsurance';
import { Appointment } from '@app/objects/Appointment';

interface PropsWithAppointment {
	appointment: Appointment;
	className?: string;
	asButton?: boolean;
}

interface DisabledLayoutProps {
	className: string;
	asButton: boolean;
}

const DisabledLayout: React.FC<DisabledLayoutProps> = ({ className, asButton }) => {
	return asButton
		? <Button disabled className={className}><i className="fa fa-medkit" /></Button>
		: (
			<span title="Appointment Insurance Coding" className={`${className} text-muted`}>
				<i className="fa fa-medkit" />
			</span>
		);
};

interface ActiveLayoutProps {
	className: string;
	appointment: Appointment;
}

const ActiveLayout: React.FC<ActiveLayoutProps> = (props) => {
	const { appointment, className } = props;

	const navigate = useNavigate();

	const hasInsurance = appointment.insurance || appointment.location?.allowAutoCoding;
	const styleIcon = hasInsurance && appointment.appointmentInsurance
		? { color: AppointmentInsuranceStatusesInfo[appointment.appointmentInsurance.status].color }
		: { color: 'grey' };
	const caption = hasInsurance && appointment.appointmentInsurance
		? AppointmentInsuranceStatusesInfo[appointment.appointmentInsurance.status].name
		: 'No insurance';

	if (appointment.appointmentInsuranceId) {
		return (
			<LinkWithPrevLocation
				className={className}
				title={caption}
				to={`/insurance-editor/${appointment.appointmentInsuranceId}`}
			>
				<i className="fa fa-medkit" style={styleIcon} />
			</LinkWithPrevLocation>
		);
	}

	return (
		<RequestButton<AppointmentInsurance>
			requestType="getAppointmentInsuranceId"
			requestProps={{
				appointmentId: appointment.id,
			}}
			onSuccess={(res) => navigate(`/insurance-editor/${res.id}`)}
			title={caption}
			className={`${className}`}
		>
			<i className="fa fa-medkit" style={styleIcon} />
		</RequestButton>
	);
};

export const LinkToInsuranceEditor: React.FC<PropsWithAppointment> = (props) => {
	const { appointment, asButton = true } = props;

	const hasInsurance = appointment.insurance || appointment.location?.allowAutoCoding;
	const className = props.className || 'btn btn-sm btn-default';

	return hasInsurance
		? <ActiveLayout appointment={appointment} className={`${asButton ? '' : 'btn-background'} ${className}`} />
		: <DisabledLayout className={className} asButton={asButton} />;
};
