import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'regenerator-runtime/runtime';

import '@common/react/yupLocaleSettings';
import { LoadingProvider } from '@common/react/components/Core/LoadingProvider/LoadingProvider';
import VideoChatModal from '@common/react/components/UI/VideoChat/VideoChatModal';
import ErrorBoundaryWithSentry from '@common/react/components/UI/ErrorBoundaryWithSentry/ErrorBoundaryWithSentry';
import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';
import VideoChatGlider from '@common/react/components/Chat/VideoChatGlider';
import Application from '@common/react/components/Core/Application/Application';
import NotificationGliderProvider from '@common/react/components/Chat/NotificationGliderProvider';
import ReactionNotificationHandler from '@common/react/components/Chat/ReactionNotificationHandler';
import { StickerProvider } from '@common/react/components/Chat/Sticker/StickerProvider';
import ChatModal from '@common/react/components/Chat/ModalPlugin/ChatModal';
import Avatar from '@common/react/components/Forms/Avatar';
import ChatNotificationHandler from '@common/react/components/Chat/ChatNotificationHandler';

import CompanyFeaturesSetter from '@app/components/UI/CompanyFeaturesSetter/CompanyFeaturesSetter';
import CompanySettingsSetter from '@app/components/UI/CompanySettingsSetter/CompanySettingsSetter';
import { UserStatusNotificationHandler } from '@app/components/UI/UserAvatarWithStatus';
import TunaLoader from '@app/components/UI/TunaLoader';
import PhoneCallModal from '@app/components/UI/PhoneCallPopupModal/PhoneCallModal';
import UserFavoritesProvider from '@app/components/UI/UserFavoritesProvider/UserFavoritesProvider';
import PhoneCallSetter from '@app/components/UI/PhoneCallSetter/PhoneCallSetter';
import skeletonImage from '@app/images/no-image-available.jpg';
import MessageFlashingTime from '@app/components/UI/MessageFlashingTitle';
import { SupportChatSettingsProvider } from '@app/components/Pages/Admin/SupportChats/SupportChats';
import RouteChangeTracker from '@app/components/Routes/RouteChangeTracker';
import UserGroupProvider from '@app/components/UI/UserGroupProvider/UserGroupProvider';
import { useSickerCollections } from '@app/components/Layouts/DashboardLayout/stickerCollections';
import { SystemChatSettingsProvider } from '@app/components/Pages/Admin/Chats/SystemChatSettingsProvider';
import { ApplicationState } from '@app/store';
import HiddenAppointmentDotsSetter from '@app/components/UI/HiddenAppointmentDotsSetter/HiddenAppointmentDotsSetter';
import { HandleNotificationReviewRequest }
	from '@app/components/Pages/Admin/Appointments/AppointmentForm/HandleNotificationReviewRequest';
import EnumAccessProvider from '@app/components/UI/EnumAccessProvider';
import CheckDevice from '@app/components/UI/CheckDevice/CheckDevice';
import { StatusListWrapper } from '@app/components/Pages/Admin/Calendar/CustomEventStatusList';
import { LocationListSynchronizer } from '@app/components/UI/LocationSelect/LocationSelect';
import { AppointmentTypesSynchronizer } from '@app/components/UI/AppointmentTypesFilter/AppointmentTypesFilter';
import { User, UserRole } from '@app/objects/User';
import { customReduxActions } from '@app/store/CustomReduxActions';
import { Init } from '@app/objects/Init';
import TranscriptionNotificationHandler
	from '@app/components/Pages/Admin/TranscriptionNotificationHandler/TranscriptionNotificationHandler';

import '@app/scss/style.scss';

const renderAvatar = (state) => (
	<Avatar
		type=""
		object={{ avatar: `${state.avatar}` }}
		readonly
		className="avatar-container"
		showOpenIcon={false}
		showDownload={false}
		withModal={false}
		firstName={state.firstName ?? 'N'}
		lastName={state.lastName ?? 'N'}
	/>
);

const notFoundPageMessage = 'Ups… page not found or you don’t have access to it. Please contact your system administrator';

const Layout: React.FC = ({ children }) => {
	const user = useSelector((state: ApplicationState) => state.login.user, shallowEqual);
	const collections = useSickerCollections();

	return (
		<div className="app-container">
			<CheckDevice />
			<ErrorBoundaryWithSentry>
				<RouteChangeTracker id="G-D5Q0SK7ZE5">
					<LoadingProvider loader={<TunaLoader />}>
						<RequestProvider notFoundPageMessage={notFoundPageMessage}>
							<Application<User, Init>
								initCustomHandler={customReduxActions}
								checkUserAccess={(user: User, roles: Array<UserRole>, props?: any) =>
									user && ((!props?.notForRoot && user.root) || roles.includes(user.role))}
							>
								<UserStatusNotificationHandler />
								<CompanyFeaturesSetter>
									<CompanySettingsSetter>
										<HiddenAppointmentDotsSetter>
											<UserGroupProvider>
												<EnumAccessProvider>
													<StickerProvider collections={collections} skeleton={skeletonImage}>
														{user ? <>
															<StatusListWrapper />
															<LocationListSynchronizer />
															<AppointmentTypesSynchronizer />
														</> : null}
														<NotificationGliderProvider>
															<HandleNotificationReviewRequest />
															<PhoneCallSetter />
															<SystemChatSettingsProvider>
																<UserFavoritesProvider>
																	{children || <Outlet />}
																</UserFavoritesProvider>
																{user ? (<>
																	<ChatModal />
																	<ReactionNotificationHandler />
																	<TranscriptionNotificationHandler />
																	<ChatNotificationHandler />
																	<VideoChatModal
																		avatarComponent={(avatar, userName) => {
																			const [firstName, lastName] = (userName || '').split(' ');
																			return renderAvatar({ avatar, firstName, lastName });
																		}}
																		skeletonImage={skeletonImage}
																	/>
																</>) : null}
															</SystemChatSettingsProvider>
															{user ? (<>
																<PhoneCallModal />
																<VideoChatGlider renderAvatar={renderAvatar} imageSkeleton={skeletonImage} />
																<MessageFlashingTime />
																<SupportChatSettingsProvider>
																	<ChatNotificationHandler withRemoteId />
																</SupportChatSettingsProvider>
															</>) : null}
														</NotificationGliderProvider>
													</StickerProvider>
												</EnumAccessProvider>
											</UserGroupProvider>
										</HiddenAppointmentDotsSetter>
									</CompanySettingsSetter>
								</CompanyFeaturesSetter>
							</Application>
						</RequestProvider>
					</LoadingProvider>
				</RouteChangeTracker>
			</ErrorBoundaryWithSentry>
		</div>
	);
};

export default Layout;
