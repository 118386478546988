import { Action, ActionCreatorsMapObject } from 'redux';

import { BaseAppThunkAction, BaseApplicationState } from '@common/react/store/index';
import { BaseUser } from '@common/react/objects/BaseUser';

type AppThunkAction<TAction> = BaseAppThunkAction<TAction, BaseUser, BaseApplicationState<BaseUser>>

export interface SwitcherObject<T> {
	/**
	 * identifier by which the element will be tracked.
	 */
	objectId: number;
	/**
	 * a value that determines how many UI elements listen for value changes.
	 */
	count: number;
	/**
	 * current value corresponding to id.
	 *
	 * may be undefined if the value has not changed since display
	 */
	value?: T;
}

export type State<T> = Array<SwitcherObject<T>>;

export enum TypeKeys {
	ADDOBJECT = 'ADDOBJECT',
	SUBTRACT = 'SUBSTRACT',
	CHANGE = 'CHANGE',
	UPDATELIST = 'UPDATELIST'
}

interface Add {
	type: TypeKeys.ADDOBJECT;
	store: string;
	objectId: number;
}

interface Subtract {
	type: TypeKeys.SUBTRACT;
	store: string;
	objectId: number;
}

interface Change<T> {
	type: TypeKeys.CHANGE;
	store: string;
	objectId: number;
	value: T;
}

interface UpdateList<T> {
	type: TypeKeys.UPDATELIST;
	store: string;
	list: Array<{objectId: number, value: T}>;
}

export type Actions<T> = Add | Subtract | Change<T> | UpdateList<T>;

export interface ActionCreators<T> extends ActionCreatorsMapObject {
	add: (objectId: number) => AppThunkAction<Actions<T>>;
	subtract: (objectId: number) => AppThunkAction<Actions<T>>;
	change: (objectId: number, value: T) => AppThunkAction<Actions<T>>;
	updateList: (list: Array<{objectId: number, value: T}>, defaultValue?: T) => AppThunkAction<Actions<T>>;
}

export const getActionCreators = <T>(store: string): ActionCreators<T> => ({
	add: (objectId: number): AppThunkAction<Actions<T>> => (dispatch) => {
		dispatch({ type: TypeKeys.ADDOBJECT, objectId, store });
	},
	subtract: (objectId: number): AppThunkAction<Actions<T>> => (dispatch) => {
		dispatch({ type: TypeKeys.SUBTRACT, objectId, store });
	},
	change: (objectId: number, value: T): AppThunkAction<Actions<T>> => (dispatch) => {
		dispatch({
			type: TypeKeys.CHANGE, objectId, value, store,
		});
	},
	updateList: (list: Array<{objectId: number, value: T}>): AppThunkAction<Actions<T>> => (dispatch) => {
		dispatch({
			type: TypeKeys.UPDATELIST, list, store,
		});
	},
});

export const getReducer = <T>(storeName: string) => (
	state: State<T> = [],
	incomingAction: Action = { type: '' },
) => {
	const action = incomingAction as Actions<T>;
	if (action.store !== storeName) {
		return state;
	}

	if (action.type === TypeKeys.UPDATELIST) {
		const { list } = action;
		const newState = [...state];
		list.forEach((item) => {
			const index = newState.findIndex((newItem) => newItem.objectId === item.objectId);
			if (index > -1) {
				newState[index] = { ...newState[index], value: item.value };
			} else if (item) {
				newState.push({ ...item, count: 1 });
			}
		});

		return newState;
	}

	let index: number = 0;
	let item: SwitcherObject<T> | undefined;
	state.forEach((value, i) => {
		if (value.objectId === action.objectId) {
			index = i;
			item = value;
		}
	});
	switch (action.type) {
		case TypeKeys.ADDOBJECT:
			if (item) {
				item.count++;
			} else {
				return state.concat({ count: 1, objectId: action.objectId });
			}
			return state;
		case TypeKeys.SUBTRACT:
			if (item) {
				if (item.count > 1) {
					item.count--;
				} else {
					return [...state.slice(0, index), ...state.slice(index + 1)];
				}
			}
			return state;
		case TypeKeys.CHANGE:
			if (item) {
				return [...state.slice(0, index), { ...item, value: action.value }, ...state.slice(index + 1)];
			}
			return state;
		default:
			return state;
	}
};
