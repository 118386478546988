import * as React from 'react';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

import '@app/scss/components/footer.scss';

import { HostOptions } from '@app/objects/HostOptions';

const year = new Date().getFullYear();

const Footer: React.FC = () => {
	const { getHostOptions } = useApplicationContext();
	const hostOptions = getHostOptions<HostOptions>();
	const northernTunaPortalAddress = hostOptions?.northernTunaPortalAddress;

	return (
		<footer className="site-footer">
			<div className="container row-content">
				<div className="site-footer__copyright">
					<span className="site-footer__copyright__text">
						©
						{' '}
						{year}
						{' '}
						NorthernTuna, all rights reserved.
					</span>
					<span>
						<LinkWithPrevLocation className="ml10" to="/privacy-policy">Privacy Policy</LinkWithPrevLocation>
						&nbsp;
						<a className="ml10 mr10" href={northernTunaPortalAddress} target="_blank" rel="noreferrer">Patients Portal</a>
					</span>
				</div>
				<div className="nt-logo">
					<img src="/amp-assets/images/logo-350x62.png" height={36} alt="Northern Tuna" />
				</div>
			</div>
		</footer>
	);
};

export default Footer;
