import React from 'react';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import Avatar from '@common/react/components/Forms/Avatar';
import SwitcherProvider from '@common/react/components/Core/SwitcherProvider/SwitcherProvider';

import { User, UserStatus } from '@app/objects/User';

const storeName = 'userStatuses';
const typeName = 'userStatuses';

interface Props {
	user: User;
	size?: number;
	className?: string;
	style?: React.CSSProperties;
	onClick?: () => void;
}

export const UserStatusDot: React.FC<{ status: UserStatus }> = ({
	status,
}) => {
	let color = '#c0c0c0';
	let title = 'Offline';
	switch (status) {
		case UserStatus.Offline:
			color = '#c0c0c0';
			title = 'Offline';
			break;
		case UserStatus.Online:
			color = '#09e32d';
			title = 'Online';
			break;
		case UserStatus.Away:
			color = '#e0eb13';
			title = 'Away';
			break;
		default:
			break;
	}

	return (
		<div className="user-status-circle">
			<i style={{ color }} title={title} className="fa fa-circle" />
		</div>
	);
};

export const UserStatusNotificationHandler = () => {
	return <SwitcherProvider<UserStatus>
		storeName={storeName}
		type={typeName}
		notificationListObjectType="UserStateNotificationObject"
		getObjectListFromNotification={(data) => {
			return data.userStatuses.map((status) => {
				return { objectId: status.id, value: status.status };
			});
		}}
	/>;
};

const UserAvatarWithStatus: React.FC<Props> = ({
	user,
	size = 50,
	className,
	style,
	onClick,
}) => {
	const right = size * 0.01;
	const avatar = (
		<Avatar
			type=""
			object={user}
			readonly
			className="avatar-container"
			showOpenIcon={false}
			showDownload={false}
			withModal
			color={user.color}
			firstName={user.firstName}
			lastName={user.lastName}
		/>
	);

	return (
		<SwitcherProvider<UserStatus>
			storeName={storeName}
			type={typeName}
			id={user.id}
			preventSubtract
		>
			{({ state: { item: status } }) => <div
				onClick={onClick}
				className={`user-avatar-with-status ${className}`}
				style={{
					height: size, width: size, fontSize: 0.4 * size, ...style,
				}}
				title={`${user.firstName} ${user.lastName} ${user.email ? `(${user.email})` : ''}`}
			>
				{onClick ? <span className="link">{avatar}</span> : (
					<LinkWithPrevLocation to={`/user-editor/${user.id}`}>
						{avatar}
					</LinkWithPrevLocation>
				)}
				<div
					style={{
						fontSize: 0.25 * size,
						lineHeight: `${0.4 * size}px`,
						'--right': `${right}px`,
						'--top': `${right}px`,
					} as React.CSSProperties}
				>
					<UserStatusDot status={typeof status !== 'undefined' ? status : user.status} />
				</div>
			</div>}
		</SwitcherProvider>
	);
};

export default UserAvatarWithStatus;
