import React from 'react';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import BaseSecureRouteWrapper from '@common/react/components/Routes/SecureRoute/BaseSecureRouteWrapper';

import {
	useCompanyFeatureProviderContext,
} from '@app/components/UI/CompanyFeaturesSetter/CompanyFeaturesSetter';
import { RoutesPropsMap } from '@app/components/Routes/SecureRoute/SecureRoutesPropsPagesMap';
import { User } from '@app/objects/User';
import { useUserGroupContext } from '@app/components/UI/UserGroupProvider/UserGroupProvider';

interface ComponentProps {
	path: string;
	redirectPath?: string;
	children?: any;
}

export const SecureRouteWrapper: React.FC<ComponentProps> = ({
	path,
	redirectPath,
	children,
}) => {
	const { checkFeatureAccess } = useCompanyFeatureProviderContext();
	const { checkUserGroupAccess } = useUserGroupContext();
	const { checkUserAccess } = useApplicationContext();

	const canRoleAccess = (route: RoutesPropsMap) =>
		!route.roles || (route.roles.length > 0 && checkUserAccess(route.roles));

	const canFeatureAccess = (route: RoutesPropsMap) =>
		!route.features || !route.features.length || (route.features.length > 0 && checkFeatureAccess(route.features));

	const canUserGroupAccess = (route: RoutesPropsMap) =>
		!route.userGroupTypes || !route.userGroupTypes.length
		|| (route.userGroupTypes.length > 0 && checkUserGroupAccess(route.userGroupTypes));

	const canAccess = (user, route): boolean => {
		return Boolean(!route || (user && (user.root
			|| (canRoleAccess(route) && canFeatureAccess(route) && canUserGroupAccess(route)))));
	};

	return (
		<BaseSecureRouteWrapper<User>
			path={path}
			canAccess={canAccess}
			redirectPath={redirectPath}
		>
			{children}
		</BaseSecureRouteWrapper>
	);
};

export default SecureRouteWrapper;
